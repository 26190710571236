import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getProperties, removeProperties, saveProperties } from 'api/geomaps/geomapsApi';
import { propertyKeys } from './queryKeyFactory';
import { PropertyAttributes } from 'domain/types';

type MutateOptions = {
  onSuccess: Function;
  onError: Function;
};

export const useProperties = () => useQuery<{ payload: PropertyAttributes[] }>(propertyKeys.all, getProperties);

export const useAddProperty = (options: MutateOptions) => {
  const queryClient = useQueryClient();

  return useMutation(saveProperties, {
    onSuccess: (_data, property) => {
      options.onSuccess(property.pop());
      queryClient.invalidateQueries(propertyKeys.all);
    },
    onError: (error) => options.onError(error),
  });
};
export const useRemoveProperty = (options: MutateOptions) => {
  const queryClient = useQueryClient();

  return useMutation(removeProperties, {
    onSuccess: (_data, property) => {
      options.onSuccess(property.pop());
      queryClient.invalidateQueries(propertyKeys.all);
    },
    onError: (error) => options.onError(error),
  });
};
