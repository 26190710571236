import { useNavigate } from 'react-router-dom';
import { useAuth } from './queries';
import Const from 'domain/constants';
import Spinner from './Spinner';

export default function Landing() {
  const navigate = useNavigate();

  useAuth({
    onSuccess: () => {
      navigate('/map');
    },
    onError: (error: string) => {
      if (error === Const.ERROR_TYPE.UNAUTHORIZED) {
        navigate('/signin');
      } else if (error === Const.ERROR_TYPE.FORBIDDEN) {
        navigate('/404')
      } else {
        navigate('/signin');
      }
    }
  });

  return (
    <div className="w-full h-full flex">
      <Spinner size="large" />
    </div>
  );
}
