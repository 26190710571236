import { useQuery } from 'react-query';
import { getProperties } from 'api/geomaps/geomapsApi';
import { propertyKeys } from 'api/geomaps/queryKeyFactory';


export const useArcGisServer = () => useQuery(propertyKeys.all, getProperties, {
  refetchOnWindowFocus: false,
  select: (data) => {
    return data?.payload[0]?.map_id;
  }
});