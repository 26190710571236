import { defineMessages } from 'react-intl'

export default defineMessages({
  textTitle: {
    id: 'SignUp.text.title',
    defaultMessage: 'Sign up'
  },
  labelEmail: {
    id: 'SignUp.label.email',
    defaultMessage: 'Email'
  },
  labelPassword: {
    id: 'SignUp.label.password',
    defaultMessage: 'Password'
  },
  labelFirstName: {
    id: 'SignUp.label.first_name',
    defaultMessage: 'First name'
  },
  labelLastName: {
    id: 'SignUp.label.last_name',
    defaultMessage: 'Last name'
  },
  labelLanguage: {
    id: 'SignUp.label.language',
    defaultMessage: 'Language'
  },
  errorRequired: {
    id: 'SignUp.error.required',
    defaultMessage: '{input} is required'
  },
  errorInvalid: {
    id: 'SignUp.error.invalid',
    defaultMessage: 'Invalid {input}'
  },
  errorTooShort: {
    id: 'SignUp.error.too_short',
    defaultMessage: '{input} must contain at least {min} characters'
  },
  textPrivacyNotice: {
    id: 'SignUp.text.privacy_notice',
    defaultMessage: 'Read our <a>privacy notice</a> about how we process your personal data.'
  },
  textTitleSignUpSuccess: {
    id: 'SignUp.title.sign_up_success',
    defaultMessage: 'Confirm email'
  },
  textSignUpSuccessInfo: {
    id: 'SignUp.text.sign_up_success_info',
    defaultMessage: 'Please confirm your account by following the link in the email sent to you and then go back to the <a>sign in page</a>.'
  },
  buttonCancel: {
    id: 'SignUp.button.cancel',
    defaultMessage: 'Cancel'
  },
  buttonSignUp: {
    id: 'SignUp.button.sign_up',
    defaultMessage: 'Sign up'
  },
  errorGeneral: {
    id: 'SignUp.error.general',
    defaultMessage: 'Something went wrong when fetching data.'
  },
  errorUserExists: {
    id: 'SignUp.error.user.exists',
    defaultMessage: 'You already have a Husqvarna ID. Use your email address to sign in.'
  },
  textSignUpEmail: {
    id: 'SignUp.email.text',
    defaultMessage: 'Proceed to GeoMaps'
  },
});
