import { FormattedMessage } from 'react-intl';
import MainLayout from 'components/MainLayout';
import messages from './messages';
import './help.css';

export default function Help() {

  const supportLink = () => (
    <a
      target="_blank"
      rel="noreferrer"
      href="mailto:support-geomaps@husqvarnagroup.com"
    >
      support-geomaps@husqvarnagroup.com
    </a>
  );

  /*
    I'd prefer we not use a specific css file. Use tailwind classes as much as possible. The benefit is that
    if we change colors, spacings, etc it will be reflected automatically.
  */
  return (
    <MainLayout>
      <h2 className="pb-base">
        <FormattedMessage {...messages.faqTitle} />
      </h2>
      <div className="list pb-sm">
        <h4><FormattedMessage {...messages.faqQ1} /></h4>
        <div>
          <FormattedMessage
            {...messages.faqA1}
            values={{
              a: supportLink
            }}
          />
        </div>
      </div>

      <h2 className="pb-base">
        <FormattedMessage {...messages.textTitle} />
      </h2>
      <p className="pb-sm">

        <FormattedMessage
          {...messages.textGeneral}
          values={{
            a: supportLink
          }}
        />
      </p>
    </MainLayout>
  );
}
