import { PopupTemplates } from 'domain/types';
import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { loadMap } from './map';

type Props = {
  server: string;
  token: string;
  popupTemplates?: PopupTemplates;
};

const darkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

const darkThemeHref = 'https://js.arcgis.com/4.23/esri/themes/dark/main.css';
const lightThemeHref = 'https://js.arcgis.com/4.23/esri/themes/light/main.css';

export default function BaseMap({ server, token, popupTemplates }: Props) {
  const intl = useIntl();

  const mapRef = useRef<HTMLDivElement>(null);

  const changeTheme = () => {
    const theme = darkMediaQuery.matches ? 'dark' : 'light';
    const darkSheet: HTMLLinkElement | null = document.querySelector(`link[href="${darkThemeHref}"]`);
    const lightSheet: HTMLLinkElement | null = document.querySelector(`link[href="${lightThemeHref}"]`);
    if (darkSheet && theme === 'light') {
      darkSheet.href = lightThemeHref;
    }
    if (lightSheet && theme === 'dark') {
      lightSheet.href = darkThemeHref;
    }
  }

  useEffect(() => {
    darkMediaQuery.addEventListener('change', changeTheme);
  }, []);

  useEffect(() => {
    if (!mapRef.current || !server) {
      return;
    }

    loadMap({
      mapRef,
      locale: intl.locale,
      server,
      token,
      map_id: server,
      popupTemplates
    });
  }, [mapRef, server, token]);

  return (
    <div
      ref={mapRef}
      className="w-full h-full"
    />
  );
}
