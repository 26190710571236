import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useAddProperty, useRemoveProperty } from 'api/geomaps/queries';
import PublicLayout from 'components/PublicLayout';
import { useMutateUser } from 'components/queries';
import ProfileForm from './ProfileForm';
import messages from './messages';

export default function Profile() {
  const intl = useIntl();

  const onError = () => {
    toast.error(intl.formatMessage(messages.errorGeneral));
  }

  const updateDiamUser = useMutateUser({
    onSuccess: () => {
      toast.success(intl.formatMessage(messages.toastUpdateSuccess));
    },
    onError
  });

  const addProperty = useAddProperty({
    onSuccess: (property: string) => {
      toast.success(intl.formatMessage(messages.toastAddPropertySuccess, { property }));
    },
    onError
  });

  const removeProperty = useRemoveProperty({
    onSuccess: (property: string) => {
      toast.success(intl.formatMessage(messages.toastRemovePropertySuccess, { property }));
    },
    onError
  });

  /*
    Why are we using the public layout here? I think we should display the form in two columns:
    user attributes to the left and properties to the right. If screen is smaller change layout to rows
    instead of columns.
  */
  return (
    <PublicLayout>
      <div className="px-lg flex flex-col basis-2 space-y-sm">
        <ProfileForm
          updateUser={updateDiamUser}
          addProperty={addProperty}
          removeProperty={removeProperty}
        />
      </div>
    </PublicLayout>
  )
}
