import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getUser, updateUser } from 'api/geomaps/geomapsApi';
import { authKeys, userKeys } from './queryKeyFactory';
import { UserAttributes } from 'domain/types';

type Options = {
  onSuccess?: Function;
  onError: Function;
};

export const useAuth = (options: Options) => useQuery(authKeys.authenticated(), getUser, {
  retry: 1,
  retryDelay: 1000,
  onSuccess: () => {
    if (options.onSuccess) {
      options.onSuccess();
    }
  },
  onError: (error) => options.onError(error),
});

export const useUser = () => useQuery<{ payload: UserAttributes }>(userKeys.all, getUser, {
  refetchOnWindowFocus: false
});

export const useMutateUser = (options: Required<Options>) => {
  const queryClient = useQueryClient();

  return useMutation(updateUser, {
    onSuccess: () => {
      options.onSuccess();
      queryClient.invalidateQueries(userKeys.all);
    },
    onError: (error) => options.onError(error),
  });
};
