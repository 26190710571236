import { FormattedMessage } from 'react-intl';
import { ReactComponent as HqvLogo } from 'assets/images/logo-husqvarna.svg';
import messages from './messages';
import './appInfo.css';

export default function AppInfo() {
  return (
    <div className="w-full h-full flex flex-col font-bold text-white app-info-background p-3xlg">
      <div>
        <HqvLogo className="fill-white w-[192px] h-[35px]" />
      </div>
      <div className="text-[64px] leading-[72px] uppercase relative">
        GeoMaps
        <div className="absolute top-[-15px] left-[260px] text-lg">
          <FormattedMessage {...messages.textTrademark} />
        </div>
      </div>
      <div className="max-w-[300px]">
        <FormattedMessage {...messages.textGeoMapsInfo} />
      </div>
    </div>
  );
}
