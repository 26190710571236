import React from 'react';
import { loadModules } from 'esri-loader';
import IMap from 'esri/Map';
import IIdentityManager from 'esri/identity/IdentityManager';
import IMapView from 'esri/views/MapView';
import IIntl from 'esri/intl';
import IWebMap from 'esri/WebMap'
import ILayerList from 'esri/widgets/LayerList';
import ILegend from 'esri/widgets/Legend';
import IExpand from 'esri/widgets/Expand';
import { goToExtent } from './mapHelpers';
import { PopupTemplates } from 'domain/types';

type LoadMapArgs = {
  mapRef: React.RefObject<HTMLDivElement>;
  locale: string;
  server: string;
  token: string;
  map_id: string;
  // layers: any;
  popupTemplates?: PopupTemplates;
};

type OrderedByProperties = {
  valueExpression: string,
  order: 'ascending' | 'descending'
}

export const loadMap = async (args: LoadMapArgs) => {
  const options = {
    css: true
  };

  const [
    IdentityManager,
    intl,
    WebMap,
    MapView,
    LayerList,
    Legend,
    Expand
  ] = await loadModules<[
    typeof IIdentityManager,
    typeof IIntl,
    typeof IWebMap,
    typeof IMapView,
    typeof ILayerList,
    typeof ILegend,
    typeof IExpand,
  ]>([
    'esri/identity/IdentityManager',
    'esri/intl',
    'esri/WebMap',
    'esri/views/MapView',
    'esri/widgets/LayerList',
    'esri/widgets/Legend',
    'esri/widgets/Expand',
  ], options);

  IdentityManager.registerToken({
    server: args.server,
    token: args.token,
  });

  const webmap = new WebMap({
    portalItem: {
      id: args.map_id
    }
  })

  const view = new MapView({
    map: webmap,
    container: args.mapRef.current || undefined
  });

  return view.when(() => {
    intl.setLocale(args.locale);
    view.ui.add(new Expand({
      view: view,
      content: new LayerList({
        view: view,
      })
    }), 'top-left');
    view.ui.add(new Expand({
      view: view,
      content: new Legend({
        view: view
      })
    }), 'top-left');

    // Center map on layers
    // goToExtent(view);

    return view;
  });
}
