import { defineMessages } from 'react-intl'

export default defineMessages({
  labelMap: {
    id: 'Navbar.label.map',
    defaultMessage: 'Map'
  },
  labelSignOut: {
    id: 'Navbar.label.sign_out',
    defaultMessage: 'Sign out'
  },
  labelInfo: {
    id: 'Navbar.label.info',
    defaultMessage: 'Info'
  },
  labelProfile: {
    id: 'Navbar.label.profile',
    defaultMessage: 'Profile'
  },
  labelHelp: {
    id: 'Navbar.label.help',
    defaultMessage: 'Help'
  },
});
