import { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { UserAttributes } from 'domain/types';
import { Locale } from 'domain/locales';
import { useProperties } from 'api/geomaps/queries';
import { useDiamLanguages } from 'api/diam/queries';
import { ReactComponent as Add } from 'assets/icons/add.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete-outlined.svg';
import Button from 'components/form/Button';
import Input from 'components/form/Input';
import Select, { toSelectable } from 'components/form/Select';
import Accordion from 'components/form/Accordion';
import ListItem from 'components/form/ListItem';
import { useUser } from 'components/queries';
import messages from './messages';

type Props = {
  updateUser: {
    mutate: Function;
  };
  addProperty: {
    mutate: Function;
  };
  removeProperty: {
    mutate: Function;
  };
};

type PropertyForm = {
  property: string;
}

export default function ProfileForm({ updateUser, addProperty, removeProperty }: Props) {
  const intl = useIntl();

  const { data: user } = useUser();
  const { data: properties } = useProperties();
  const { data: countries } = useDiamLanguages();

  const selectableCountries = countries?.data
    ?.map(({ id, attributes: { name } }) => ([id, name]))
    .map(toSelectable)
    .filter(c => Object.values<string>(Locale).includes(c.value))
    ?? [];

  const { register, handleSubmit, formState: { errors }, reset } = useForm<UserAttributes>();
  const propertyForm = useForm<PropertyForm>();

  useEffect(() => user?.payload && reset({
    first_name: user.payload.first_name,
    last_name: user.payload.last_name,
    language: user.payload.language,
    telephone_number: user.payload.telephone_number
  }), [user]);

  const onSubmit: SubmitHandler<UserAttributes> = (formData: Partial<UserAttributes>) => updateUser.mutate(formData);

  const onSubmitProperty: SubmitHandler<PropertyForm> = (formData: PropertyForm) => addProperty.mutate([formData.property]);

  return (
    <>
      <h2>
        <FormattedMessage {...messages.textTitle} />
      </h2>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex columns-2 gap-sm">
          <Input
            name="first_name"
            label={intl.formatMessage(messages.labelFirstName)}
            register={register('first_name', {
              required: intl.formatMessage(messages.errorRequired, { input: intl.formatMessage(messages.labelFirstName) })
            })}
            disabled
          />
          <Input
            name="last_name"
            label={intl.formatMessage(messages.labelLastName)}
            register={register('last_name', {
              required: intl.formatMessage(messages.errorRequired, { input: intl.formatMessage(messages.labelLastName) })
            })}
            disabled
          />
        </div>
        <div className="flex gap-sm">
          <div className="basis-1/2">
            <Input
              name="email"
              label={intl.formatMessage(messages.labelEmail)}
              disabled
              register={{ value: user?.payload?.email || '' }}
            />
          </div>
          <div className="basis-1/2">
            <Input
              name="telephone_number"
              label={intl.formatMessage(messages.labelPhone)}
              error={errors.telephone_number?.message}
              register={register('telephone_number', {
                pattern: {
                  value: /^\d{10}$/,
                  message: intl.formatMessage(messages.errorInvalid, { input: intl.formatMessage(messages.labelPhone) })
                }
              })}
              disabled
            />
          </div>
        </div>
        <div className="flex gap-sm">
          <div className="basis-1/2">
            <Select
              name="language"
              label={intl.formatMessage(messages.labelLanguage)}
              options={selectableCountries}
              error={errors.language?.message}
              register={register('language', {
                required: intl.formatMessage(messages.errorRequired, { input: intl.formatMessage(messages.labelLanguage) })
              })}
            />
          </div>
          <div className="basis-1/2 my-auto">
            <Button type="submit" size='small'>
              <FormattedMessage {...messages.buttonSave} />
            </Button>
          </div>
        </div>
      </form>

      <hr className="border-brand-90" />

      <form onSubmit={propertyForm.handleSubmit(onSubmitProperty)}>
        <div className="flex">
          <Accordion
            label={intl.formatMessage(messages.labelProperties)}
            expanded
          >
            {properties?.payload?.map(({ address }) =>
              <ListItem key={address}>
                {address}
                <Button state="none" size="none">
                  <DeleteIcon onClick={() => removeProperty.mutate([address])} />
                </Button>
              </ListItem>
            )}
          </Accordion>
        </div>
        <div className="flex gap-sm">
          <div className="basis-11/12">
            <Input
              name="property"
              label={intl.formatMessage(messages.labelAddProperty)}
              register={propertyForm.register('property')}
            />
          </div>
          <div className="basis-1/12">
            <Button type="submit" size="large" disabled={!propertyForm.formState.isValid}>
              <Add />
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}
