import { defineMessages } from 'react-intl'

export default defineMessages({
  textTitle: {
    id: 'SignIn.text.title',
    defaultMessage: 'Sign in'
  },
  textCaption: {
    id: 'SignIn.text.caption',
    defaultMessage: 'Gain a higher level of control than ever before with Husqvarna GeoMaps.'
  },
  buttonCancel: {
    id: 'SignIn.button.cancel',
    defaultMessage: 'Cancel'
  },
  buttonSignIn: {
    id: 'SignIn.button.sign_in',
    defaultMessage: 'Sign in'
  },
  textNoAccount: {
    id: 'SignIn.text.no_account',
    defaultMessage: 'Don’t have an account?'
  },
  linkSignUp: {
    id: 'SignIn.link.sign_up',
    defaultMessage: 'Sign up now'
  },
  textNeedHelp: {
    id: 'SignIn.text.need_help',
    defaultMessage: 'Need help signing in? <a>Contact us</a>'
  },
});
