import { defineMessages } from 'react-intl';

export default defineMessages({
  textTitle: {
    id: 'Help.text.title',
    defaultMessage: 'Help'
  },
  textGeneral: {
    id: 'Help.text.general',
    defaultMessage: 'If you need help or have suggestions please contact the GeoMaps team at <a>email</a>.'
  },
  faqTitle: {
    id: 'Help.faq.title',
    defaultMessage: 'FAQ'
  },
  faqQ1: {
    id: 'Help.faq.q1',
    defaultMessage: 'I would like my information deleted.'
  },
  faqA1: {
    id: 'Help.faq.a1',
    defaultMessage: 'Please email us at <a>email</a> and we will take the necessary steps to delete your account and the associated data.'
  }
});
