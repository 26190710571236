import { defineMessages } from 'react-intl'

export default defineMessages({
  textGeoMapsInfo: {
    id: 'AppInfo.text.geomaps_info',
    defaultMessage: 'Husqvarna GeoMaps is an interactive and comprehensive digital platform for large-scale vegetation management and provides you with the absolute best basis for making the best and most cost-effective decisions.'
  },
  textTrademark: {
    id: 'AppInfo.text.trademark',
    defaultMessage: 'TM'
  },
});
