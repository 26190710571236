import { defineMessages } from 'react-intl'

export default defineMessages({
  textAnalysisNotReadyTitle: {
    id: 'Analysis.bark_beetle.title.analysis_not_ready_title',
    defaultMessage: 'Your property is under monitoring'
  },
  textAnalysisNotReadyInfo: {
    id: 'Analysis.bark_beetle.title.analysis_not_ready_info',
    defaultMessage: 'The analysis should be available within two business days.'
  }
});
